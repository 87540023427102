import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Seo from '../components/Seo';
import FooterCredit from '../components/FooterCredit';
import { Constrain, GeneralBody } from '../components/Containers';
import { Article, PageTitle } from '../components/Typography';
import SingleFeature from '../components/Features/SingleFeature';
import AvailableInTags from '../components/AvailableInTags';

type PageContextType = {
	slug: string;
};

export default function FeatureTemplate(
	props: PageProps<GatsbyTypes.SingleFeaturePageDataQuery, PageContextType>
) {
	const { data } = props;

	return (
		<>
			<Seo
				description={data?.feature?.frontmatter?.excerpt}
				title={data?.feature?.frontmatter?.title}
				featuredImage={
					data?.feature?.frontmatter?.featuredImage?.childImageSharp
						?.gatsbyImageData
				}
			/>
			<Constrain className="narrow">
				<GeneralBody>
					<PageTitle className="centered narrow">
						{data?.feature?.frontmatter?.subHeading}
					</PageTitle>
					<Article className="subheader">
						{data?.feature?.frontmatter?.desc}
					</Article>
					<AvailableInTags
						neededPlan={data.feature?.frontmatter?.neededPlan}
						layout="centered"
						hasTopGutter
					/>
					<SingleFeature data={data} />
				</GeneralBody>
			</Constrain>
			<FooterCredit />
		</>
	);
}

export const query = graphql`
	query SingleFeaturePageData($slug: String!) {
		features: allMdx(
			filter: { fileAbsolutePath: { glob: "**/site-data/features/**/*.mdx" } }
			sort: { order: ASC, fields: frontmatter___order }
		) {
			edges {
				node {
					id
					frontmatter {
						heading
					}
					fields {
						slug
					}
				}
			}
		}
		feature: mdx(fields: { slug: { eq: $slug } }) {
			frontmatter {
				youtubeVideoId
				heading
				subHeading
				title
				desc
				released
				neededPlan
				image {
					childImageSharp {
						gatsbyImageData(
							layout: CONSTRAINED
							width: 960
							placeholder: BLURRED
						)
					}
				}
				featuredImage {
					childImageSharp {
						gatsbyImageData(layout: CONSTRAINED, width: 1200)
					}
				}
				catches {
					title
					desc
					image {
						childImageSharp {
							gatsbyImageData(
								layout: CONSTRAINED
								width: 128
								placeholder: TRACED_SVG
							)
						}
					}
				}
				excerpt
			}
			body
			excerpt(pruneLength: 200, truncate: true)
			fields {
				slug
			}
		}
		testimonials: allMdx(
			filter: {
				fileAbsolutePath: { glob: "**/site-data/testimonials/**/*.mdx" }
			}
			sort: { order: ASC, fields: frontmatter___order }
		) {
			edges {
				node {
					id
					frontmatter {
						title
						link
						star
						name
					}
					body
				}
			}
		}
	}
`;
