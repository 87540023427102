import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { gv, ns } from '../../utils';
import { Article } from '../Typography';

const Container = styled.div`
	padding: ${props => ns(props.theme.gutter)}
		${props => ns(props.theme.gutter * 2)};
	border-left: 4px solid ${gv('borderColorBase')};
	background-color: ${gv('backgroundControl')};
	color: ${gv('textColorSecondary')};
	a {
		color: inherit;
		&:hover {
			color: inherit;
		}
	}
	&.alert--error {
		background-color: ${gv('errorBackgroundColor')};
		border-color: ${gv('errorColor')};
	}
	&.alert--warn {
		background-color: ${gv('warningBackgroundColor')};
		border-color: ${gv('warningColor')};
	}
	&.alert--success {
		background-color: ${gv('successBackgroundColor')};
		border-color: ${gv('successColor')};
	}
	&.gutter-bottom {
		margin-bottom: ${props => ns(props.theme.gutter * 2)};
	}
	&.gutter-top {
		margin-top: ${props => ns(props.theme.gutter * 2)};
	}
`;

export type AlertProps = {
	type: 'error' | 'warn' | 'success' | 'regular';
	hasGutterBottom?: boolean;
	hasGutterTop?: boolean;
};
export default function Alert(props: PropsWithChildren<AlertProps>) {
	const {
		type,
		children,
		hasGutterBottom = false,
		hasGutterTop = false,
	} = props;
	return (
		<Container
			className={classNames('alert', `alert--${type}`, {
				'gutter-bottom': hasGutterBottom,
				'gutter-top': hasGutterTop,
			})}
		>
			<Article>{children}</Article>
		</Container>
	);
}
