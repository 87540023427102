import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { gv, ns } from '../../utils';
import TestimonialsWidget from '../Testimonials/Widget';
import { Divider, Article } from '../Typography';
import YoutubeEmbed from '../YoutubeEmbed';
import Catches from './Catches';
import classNames from 'classnames';
import Alert from '../Alert';
import Cta from '../Cta';
import { MainHeading, Main, Grid, Sidebar, SidebarHeading } from '../SiteGrid';

const Container = styled.article`
	margin: ${props => ns(props.theme.gutter * 2)} 0;
`;

const FeaturesList = styled.ul`
	list-style: none;
	padding: ${props => ns(props.theme.gutter)};
	border-radius: ${props => ns(props.theme.borderRadiusBase)};
	background-color: ${gv('backgroundShade')};
	li a {
		display: block;
		padding: ${props => ns(props.theme.gutter / 2)}
			${props => ns(props.theme.gutter)};
		border-left: 2px solid ${gv('borderColorLight')};
		color: ${gv('textColorSecondary')};
		font-family: ${props => props.theme.fontFamilyHeading};
		font-weight: bold;
		font-size: ${props => ns(props.theme.fz.large1)};
		&.current {
			border-left-color: ${gv('primaryLightColor')};
			background-color: ${gv('backgroundSelected')};
		}
	}
`;

export type SingleFeatureProps = {
	data: GatsbyTypes.SingleFeaturePageDataQuery;
};
export default function SingleFeature(props: SingleFeatureProps) {
	const {
		data: { feature, testimonials, features },
	} = props;
	return (
		<Container>
			{feature?.frontmatter?.released === false ? (
				<Alert type="warn" hasGutterBottom>
					<strong>NOTICE</strong> This feature hasn't been released yet. Please{' '}
					<Link to="/subscribe/">subscribe</Link> to get notified when we
					release.
				</Alert>
			) : null}
			{feature?.frontmatter?.youtubeVideoId ? (
				<YoutubeEmbed videoId={feature.frontmatter.youtubeVideoId} />
			) : (
				<GatsbyImage
					image={feature!.frontmatter!.image!.childImageSharp!.gatsbyImageData}
					alt={feature!.frontmatter!.subHeading!}
				/>
			)}
			<Catches catches={feature?.frontmatter?.catches ?? []} hasGutterTop />
			<Divider />
			<Grid>
				<Main>
					<MainHeading>{feature?.frontmatter?.heading}</MainHeading>
					<Article>
						<MDXRenderer>{feature!.body}</MDXRenderer>
					</Article>
					<Cta.Group align="left">
						<Cta.GroupHeading>SEE NEXT</Cta.GroupHeading>
						<Cta type="primary" to="/examples/">
							Browse Examples
						</Cta>
						<Cta type="default" to="/pricing/">
							Get WPEForm Now
						</Cta>
					</Cta.Group>
				</Main>
				<Sidebar>
					<SidebarHeading>All Features</SidebarHeading>
					<FeaturesList>
						{features.edges.map(({ node }) => (
							<li key={node.id}>
								<Link
									to={node.fields!.slug as string}
									className={classNames({
										current: node.fields?.slug === feature?.fields?.slug,
									})}
								>
									{node.frontmatter?.heading}
								</Link>
							</li>
						))}
					</FeaturesList>
					<SidebarHeading>Testimonials</SidebarHeading>
					<TestimonialsWidget data={testimonials} narrow />
					<Cta.Group align="center">
						<Cta to="/pricing/" size="large" type="accent-secondary">
							Get Started Now
						</Cta>
					</Cta.Group>
				</Sidebar>
			</Grid>
		</Container>
	);
}
