import styled from 'styled-components';
import { bp, gridContainerCss, ns } from '../../utils';

export const MainHeading = styled.h2<{ dense?: boolean }>`
	margin: 0 0 ${props => ns(props.theme.gutter * (props.dense ? 0.5 : 1.5))} 0;
	line-height: 1.2;
	clear: both;
	font-family: ${props => props.theme.fontFamilyHeading};
	font-size: ${props => ns(props.theme.fz.large3 / props.theme.fz.base, 'em')};
`;

export const Main = styled.div``;

export const SidebarHeading = styled.p<{ dense?: boolean }>`
	display: block;
	font-family: ${props => props.theme.fontFamilyHeading};
	font-weight: bold;
	font-size: ${props => ns(props.theme.fz.large2)};
	line-height: 1.5;
	margin: 0 0 ${props => ns(props.theme.gutter * (props.dense ? 0.5 : 1.5))} 0;
	&:not(:first-child) {
		margin-top: ${props => ns(props.theme.gutter * (props.dense ? 1 : 1.5))};
	}
`;

export const Sidebar = styled.aside`
	flex: 0 0 100%;
	max-width: 100%;
	&:first-child {
		margin-bottom: ${props => ns(props.theme.gutter * 2)};
	}
	@media ${props => bp(props, 'tablet')} {
		margin-bottom: 0;
		top: 100px;
		position: sticky;
	}
`;

export const Grid = styled.div`
	${gridContainerCss};
	align-items: flex-start;
	margin: ${props => ns(props.theme.gutter)} 0 0 0;
	${Main} {
		padding-bottom: ${props => ns(props.theme.gutter)};
		flex: 0 0 100%;
	}

	@media ${props => bp(props, 'tablet')} {
		${Sidebar} {
			flex-basis: 300px;
			width: 300px;
		}
		${Main} {
			flex-basis: calc(100% - 300px);
			padding-right: ${props => ns(props.theme.gutter)};
			&:last-child {
				padding-right: 0;
				padding-left: ${props => ns(props.theme.gutter)};
			}
		}
	}
	@media ${props => bp(props, 'desktop')} {
		${Sidebar} {
			flex-basis: 350px;
			width: 350px;
		}
		${Main} {
			padding-right: ${props => ns(props.theme.gutter * 2)};
			&:last-child {
				padding-right: 0;
				padding-left: ${props => ns(props.theme.gutter * 2)};
			}
			flex-basis: calc(100% - 350px);
		}
	}
`;

export const Columns = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-gap: ${props => ns(props.theme.gutter)};
`;

export const Column = styled.div`
	grid-column: auto / span 12;

	// default is full

	// for half
	&.half {
		@media ${props => bp(props, 'tablet')} {
			grid-column: auto / span 6;
		}
	}

	// for third
	&.third {
		@media ${props => bp(props, 'tablet')} {
			grid-column: auto / span 6;
		}
		@media ${props => bp(props, 'desktop')} {
			grid-column: auto / span 4;
		}
	}

	// for two third
	&.two-third {
		@media ${props => bp(props, 'tablet')} {
			grid-column: auto / span 6;
		}
		@media ${props => bp(props, 'desktop')} {
			grid-column: auto / span 8;
		}
	}

	// for fourth
	&.fourth {
		@media ${props => bp(props, 'tablet')} {
			grid-column: auto / span 6;
		}
		@media ${props => bp(props, 'desktop')} {
			grid-column: auto / span 3;
		}
	}
`;
