import React from 'react';
import styled from 'styled-components';
import { ns } from '../../utils';

export const VideoWrapper = styled.div`
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
	margin: 0 0 ${props => ns(props.theme.gutter)} 0;
	overflow: hidden;
	border-radius: ${props => ns(props.theme.borderRadiusBase)};

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;

export type YoutubeEmbedProps = {
	videoId: string;
};
export default function YoutubeEmbed(props: YoutubeEmbedProps) {
	const { videoId } = props;

	return (
		<VideoWrapper className="hero-youtube-embed">
			<iframe
				title="Video Preview"
				width="560"
				height="315"
				src={`https://www.youtube.com/embed/${videoId}`}
				frameBorder="0"
				allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			/>
		</VideoWrapper>
	);
}
