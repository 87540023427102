import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import { bp, gridContainerCss, gv, ns } from '../../utils';
import { ReactComponent as BlobOne } from '../../images/blob-one.svg';
import { ReactComponent as BlobTwo } from '../../images/blob-two.svg';
import { ReactComponent as BlobThree } from '../../images/blob-three.svg';
import { ReactComponent as BlobFour } from '../../images/blob-four.svg';

const Blobs = [<BlobOne />, <BlobTwo />, <BlobThree />, <BlobFour />];

const CatchBgBlob = styled.div`
	position: absolute;
	top: -20px;
	left: -15px;
	bottom: -10px;
	right: -15px;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		width: 100%;
		height: auto;
		fill: ${gv('blobColor')};
	}
`;
const CatchImage = styled.div`
	height: 64px;
	width: 64px;
	margin: 0 auto;
	flex: 0 0 64px;
	margin: 0 auto ${props => ns(props.theme.gutter)} auto;
	position: relative;
	.gatsby-image-wrapper {
		position: relative;
		z-index: 1;
	}
`;
const CatchBody = styled.div`
	flex: 0 0 100%;
`;
const CatchTitle = styled.h3`
	font-family: ${props => props.theme.fontFamilyHeading};
	font-size: ${props => props.theme.fz.base};
	color: ${gv('textColor')};
	font-weight: bold;
	text-align: center;
	margin: 0 0 ${props => ns(props.theme.gutter)} 0;
`;
const CatchDesc = styled.p`
	margin: 0;
	text-align: center;
	font-size: ${props => ns(props.theme.fz.base)};
	color: ${gv('textColor')};
	line-height: 1.5;
`;

const CatchContainer = styled.div`
	flex: 0 0 100%;
	padding: ${props => ns(props.theme.gutter)};
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	align-content: flex-start;
`;

const Container = styled.div`
	${gridContainerCss};
	justify-content: center;
	align-items: stretch;

	&.has-gutter-top {
		margin-top: ${props => ns(props.theme.gutter * 1.5)};
	}

	@media ${props => bp(props, 'tablet')} {
		${CatchContainer} {
			flex-basis: 50%;
			${CatchDesc} {
				max-width: 350px;
				margin: 0 auto;
			}
		}
	}
	@media ${props => bp(props, 'desktop')} {
		${CatchContainer} {
			flex-basis: 33.333333%;
		}
		&.is-four {
			${CatchContainer} {
				flex-basis: 50%;

				${CatchBody} {
					flex-basis: calc(100% - 64px);
					padding: 0 0 0 ${props => ns(props.theme.gutter)};
					${CatchTitle},
					${CatchDesc} {
						max-width: none;
						text-align: left;
					}
					${CatchTitle} {
						margin-bottom: ${props => ns(props.theme.gutter / 2)};
					}
					${CatchDesc} {
						margin: 0;
					}
				}
			}
		}
	}
`;

export type CatchesProps = {
	catches: NonNullable<
		NonNullable<
			GatsbyTypes.LandingPageDataQuery['features']['edges'][0]['node']['frontmatter']
		>['catches']
	>;
	hasGutterTop?: boolean;
};
export default function Catches(props: CatchesProps) {
	const { catches, hasGutterTop = false } = props;
	const totalCatch = catches.length;
	if (!totalCatch) {
		return null;
	}
	return (
		<Container
			className={classNames('catchy', {
				'has-gutter-top': hasGutterTop,
				'is-four': totalCatch % 4 === 0,
			})}
		>
			{catches.map((item, index) => (
				<CatchContainer key={index}>
					{item?.image?.childImageSharp ? (
						<CatchImage>
							<CatchBgBlob>{Blobs[index % 4]}</CatchBgBlob>
							<GatsbyImage
								image={item.image.childImageSharp.gatsbyImageData}
								alt={item.title as string}
							/>
						</CatchImage>
					) : null}
					<CatchBody>
						<CatchTitle>{item?.title}</CatchTitle>
						<CatchDesc>{item?.desc}</CatchDesc>
					</CatchBody>
				</CatchContainer>
			))}
		</Container>
	);
}
